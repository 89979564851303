import { createActionGroup, props } from '@ngrx/store';
import { localizationFeatureKey } from './selectors';

export const LocalizationActions = createActionGroup({
  source: localizationFeatureKey,
  events: {
    setLang: props<{ lang: string }>(),
    setLangSuccess: props<{ lang: string }>(),
  },
});
