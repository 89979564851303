import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LocalizationState } from './state';

export const localizationFeatureKey = 'localizationFeature';

export const selectFeatureState = createFeatureSelector<LocalizationState>(localizationFeatureKey);

export const selectPrefLang = createSelector(
  selectFeatureState,
  (state: LocalizationState) => state.preferLang,
);
